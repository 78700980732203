<template>
  <h1>Hello Admin !</h1>
</template>

<script>
export default {
  name: "HelloDashboard"
}
</script>

<style scoped>

</style>